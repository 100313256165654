var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './ToolTip.style';
var ToolTip = function ToolTip(_ref) {
  var title = _ref.title,
    showToolTip = _ref.showToolTip,
    className = _ref.className,
    children = _ref.children;
  return __jsx("div", {
    className: "nva-tooltip ".concat(showToolTip && 'is-open', " ").concat(className)
  }, children, __jsx("span", {
    className: "nva-tooltip__text",
    role: "tooltip"
  }, title));
};
ToolTip.defaultProps = {
  className: ''
};
export default styled(ToolTip).withConfig({
  componentId: "sc-3cfph0-0"
})(["", ";"], styles);