import _defineProperty from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import React, { useState } from 'react';
import SocialIcon from '../../atoms/SocialIcon';
import ToolTip from '../../atoms/ToolTip';
import CustomIcon from '../../atoms/CustomIcon';
import componentAnalytics from '../../../constants/analyticsConstants';
import styled from 'styled-components';
import styles from './SocialIcons.style';
var SocialIconBlock = styled.section.withConfig({
  componentId: "sc-1lai8ft-0"
})(["", ";"], styles);
var TOOTLTIP_MESSAGES = {
  LINK_COPIED: 'Link Copied',
  COPY_LINK: 'Copy Link'
};
var TOOTLTIP_INITIAL_STATE = {
  show: false,
  message: TOOTLTIP_MESSAGES.COPY_LINK
};
var SocialIcons = function SocialIcons(_ref) {
  var locationName = _ref.locationName,
    socialIconsData = _ref.socialIconsData,
    dataAnalyticsSocialLinks = _ref.dataAnalyticsSocialLinks,
    styleType = _ref.styleType;
  var _useState = useState(_objectSpread({}, TOOTLTIP_INITIAL_STATE)),
    toolTipState = _useState[0],
    setToolTipState = _useState[1];
  var facebookIcon = socialIconsData.facebookIcon,
    facebookUrl = socialIconsData.facebookUrl,
    twitterUrl = socialIconsData.twitterUrl,
    twitterIcon = socialIconsData.twitterIcon,
    instagramUrl = socialIconsData.instagramUrl,
    instagramIcon = socialIconsData.instagramIcon,
    linkedInUrl = socialIconsData.linkedInUrl,
    linkedInIcon = socialIconsData.linkedInIcon,
    youTubeUrl = socialIconsData.youTubeUrl,
    youTubeIcon = socialIconsData.youTubeIcon,
    shareUrl = socialIconsData.shareUrl,
    shareIcon = socialIconsData.shareIcon;
  var dataAnalyticsSocial = dataAnalyticsSocialLinks;
  var hideToolTip = function hideToolTip() {
    return setToolTipState({
      message: TOOTLTIP_MESSAGES.COPY_LINK,
      show: false
    });
  };
  var showLinkCopiedMessage = function showLinkCopiedMessage() {
    return setToolTipState({
      show: true,
      message: TOOTLTIP_MESSAGES.LINK_COPIED
    });
  };
  var copiedLinkTrigger = function copiedLinkTrigger() {
    navigator.clipboard.writeText(shareUrl);
    showLinkCopiedMessage();
    setTimeout(function () {
      hideToolTip();
    }, 2000);
  };
  return __jsx(SocialIconBlock, {
    className: "nva-social-icons social-icons"
  }, shareUrl && __jsx("button", {
    className: "nva-social-icon nva-social-icons__copy-button",
    onClick: copiedLinkTrigger
  }, __jsx(ToolTip, {
    title: toolTipState.message,
    showToolTip: toolTipState.show
  }, __jsx(CustomIcon, {
    icon: shareIcon,
    className: "nva-social-icons__copy-icon"
  }))), facebookUrl && __jsx(SocialIcon, {
    dataAnalyticsType: "icon-social-feed",
    locationName: locationName,
    dataAnalyticsValue: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_FACEBOOK,
    dataAnalyticsVariation: dataAnalyticsSocial && dataAnalyticsSocial.variation ? dataAnalyticsSocial.variation : '',
    socialIconType: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_FACEBOOK,
    socialUrl: facebookUrl,
    icon: facebookIcon,
    className: "nva-social-icons__facebook-icon"
  }), twitterUrl && __jsx(SocialIcon, {
    dataAnalyticsType: "icon-social-feed",
    locationName: locationName,
    dataAnalyticsValue: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_TWITTER,
    dataAnalyticsVariation: dataAnalyticsSocial && dataAnalyticsSocial.variation ? dataAnalyticsSocial.variation : '',
    socialIconType: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_TWITTER,
    socialUrl: twitterUrl,
    icon: twitterIcon,
    className: "nva-social-icons__twitter-icon"
  }), instagramUrl && __jsx(SocialIcon, {
    dataAnalyticsType: "icon-social-feed",
    locationName: locationName,
    dataAnalyticsValue: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_INSTAGRAM,
    dataAnalyticsVariation: dataAnalyticsSocial && dataAnalyticsSocial.variation ? dataAnalyticsSocial.variation : '',
    socialIconType: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_INSTAGRAM,
    socialUrl: instagramUrl,
    icon: instagramIcon,
    className: "nva-social-icons__instagram-icon"
  }), linkedInUrl && __jsx(SocialIcon, {
    dataAnalyticsType: "icon-social-feed",
    locationName: locationName,
    dataAnalyticsValue: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_LINKEDIN,
    dataAnalyticsVariation: dataAnalyticsSocial && dataAnalyticsSocial.variation ? dataAnalyticsSocial.variation : '',
    socialIconType: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_LINKEDIN,
    socialUrl: linkedInUrl,
    icon: linkedInIcon,
    className: "nva-social-icons__linkedin-icon"
  }), youTubeUrl && __jsx(SocialIcon, {
    dataAnalyticsType: "icon-social-feed",
    locationName: locationName,
    dataAnalyticsValue: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_YOUTUBE,
    dataAnalyticsVariation: dataAnalyticsSocial && dataAnalyticsSocial.variation ? dataAnalyticsSocial.variation : '',
    socialIconType: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_YOUTUBE,
    socialUrl: youTubeUrl,
    icon: youTubeIcon,
    className: "nva-social-icons__youtube-icon"
  }));
};
SocialIcons.defaultProps = {
  socialIconsData: '',
  dataAnalyticsSocialLinks: '',
  styleType: ''
};
export default SocialIcons;